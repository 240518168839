<template>
  <div>
    <v-card v-for="(duplicate, index) in duplicates" :key="index" outlined class="mb-4">
      <v-card-title>
        {{ duplicate._id.size }}

        <v-spacer />

        <vx-btn color="primary" @click="mergeDuplicates(duplicate)">Merge</vx-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col v-for="(file, index) in duplicate.files" :key="index" cols="3">
            <v-card @click="$copy(file.url)">
              <vx-img class="rounded cursor-pointer" size="400" square :src="$getImage(file.url, 800)" />
              <v-list-item-title :title="file.name" class="pa-2 text-caption">
                {{ file.name }}
              </v-list-item-title>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { FileService } from '@tutti/services';

export default {
  name: 'DuplicateFiles',

  props: {
    value: { type: Boolean, required: true },
  },

  data() {
    return {
      duplicates: [],
    };
  },

  async created() {
    await this.getDuplicates();
  },

  methods: {
    async getDuplicates() {
      const response = await FileService.getDuplicates({});

      if (response) {
        this.duplicates = response.data;
      }
    },

    async mergeDuplicates(duplicate) {
      const response = await FileService.mergeDuplicates({ fileIds: duplicate.files.map(file => file._id) });

      if (response) {
        this.getDuplicates();
      }
    },
  },
};
</script>
