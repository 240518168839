var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.duplicates, function (duplicate, index) {
    return _c('v-card', {
      key: index,
      staticClass: "mb-4",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-title', [_vm._v(" " + _vm._s(duplicate._id.size) + " "), _c('v-spacer'), _c('vx-btn', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.mergeDuplicates(duplicate);
        }
      }
    }, [_vm._v("Merge")])], 1), _c('v-card-text', [_c('v-row', _vm._l(duplicate.files, function (file, index) {
      return _c('v-col', {
        key: index,
        attrs: {
          "cols": "3"
        }
      }, [_c('v-card', {
        on: {
          "click": function click($event) {
            return _vm.$copy(file.url);
          }
        }
      }, [_c('vx-img', {
        staticClass: "rounded cursor-pointer",
        attrs: {
          "size": "400",
          "square": "",
          "src": _vm.$getImage(file.url, 800)
        }
      }), _c('v-list-item-title', {
        staticClass: "pa-2 text-caption",
        attrs: {
          "title": file.name
        }
      }, [_vm._v(" " + _vm._s(file.name) + " ")])], 1)], 1);
    }), 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }